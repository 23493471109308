<template>
    <a-card>
        <a-page-header
            :title='"项目管理"'
        />
        <a-form-model
            ref="form"
            :model="form"
            v-bind='layout'
        >
            <a-row>
                <a-col :span="7">
                    <a-form-model-item label="项目名称">
                        <a-input
                            v-model="form.projectName"
                            placeholder="请输入"
                        />
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="7">
                    <a-form-model-item label="品牌">
                        <a-select
                            mode="multiple"
                            v-model="form.principalIdList"
                            placeholder="请选择品牌"
                            allow-clear
                            show-search
                            option-filter-prop="children"
                        >
                            <a-select-option
                                v-for="item in principalList"
                                :key="item.id"
                            >
                                {{ item.principal_name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="8">
                    <a-form-model-item label="项目状态">
                        <a-select
                            mode="multiple"
                            v-model="form.projectStatusList"
                            placeholder="请选择"
                            allow-clear
                            show-search
                            option-filter-prop="children"
                        >
                            <a-select-option
                                v-for="item in statusList"
                                :key="item.id"
                            >
                                {{ item.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col class="flex-end">
                    <a-space>
                        <a-button @click="handleReset">重置</a-button>
                        <a-button type="primary" @click="handleSearch">查询</a-button>
                        <a-button type="primary" @click="jumpDetailPage(0)">新建项目</a-button>
                    </a-space>
                </a-col>
            </a-row>
        </a-form-model>

        <a-table
            :loading="loading"
            :columns="columns"
            :data-source="dataList"
            :row-key="(record, index) => index"
            :pagination='false'
            class="mt-20"
        >
            <div slot="action" slot-scope="text, record">
                <base-button
                    :type="'link'"
                    :title="'修改'"
                    @onClickBtn="jumpDetailPage(record.id)"
                ></base-button>
                <base-button
                    :type="'link'"
                    :title="'删除'"
                    @onClickBtn="deleteProject(record.id)"
                    v-if="record.projectStatus == 1"
                ></base-button>
                <base-button
                    :type="'link'"
                    :title="'暂停'"
                    @onClickBtn="actionProject('stopById', record.id)"
                    v-if="record.projectStatus == 3"
                ></base-button>
                <base-button
                    :type="'link'"
                    :title="'开启'"
                    @onClickBtn="actionProject('enableById', record.id)"
                    v-if="record.projectStatus == 5"
                ></base-button>
            </div>
        </a-table>

        <base-pagination
            :currentPage="pagination.current"
            :pageSize="pagination.pageSize"
            :total="pagination.total"
            @onChange="handlePaginationChange"
            @onShowSizeChange="handlePaginationChange"
        />
    </a-card>
</template>

<script>
const statusList = [
    { id: 1, name: '草稿' },
    { id: 2, name: '有任务，未投放' },
    { id: 3, name: '进行中' },
    { id: 4, name: '结束' },
    { id: 5, name: '暂停' },
]

// 单位转化为万，保留4位数
function formatUnit(value) {
    if((value ?? '') !== '') {
        let val = Number(value)
        return val > 1e4 ? (val/1e4).toFixed(2) + '万' : val.toFixed(2)
    } else {
        return '-'
    }
}

export default {
    data() {
        return {
            layout: {
                labelCol: { span: 8 },
                wrapperCol: { span: 16 }
            },
            form: {},
            principalList: [],
            statusList,
            loading: false,
            columns: [
                {
                    align: 'center',
                    title: '项目编码',
                    dataIndex: 'projectCode',
                    customRender: (text, row, index) => {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '项目名称',
                    dataIndex: 'projectName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '项目品牌',
                    dataIndex: 'principalName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '项目状态',
                    customRender:(text, row) => {
                        let state = row.projectStatus
                        let txt = state ? (statusList.filter(item => item.id == state))[0].name : '-'
                        
                        return state == 3 ? <div style="color: #52c41a;">{txt}</div> : state == 5 ? <div class="red">{txt}</div> : txt
                    }
                },
                {
                    align: 'center',
                    title: '项目预估周期',
                    customRender:(text, row) => {
                        return <div><div>{row.startTime || '-'}</div> ~ <div>{row.endTime || '-'}</div></div>
                    }
                },
                {
                    align: 'center',
                    title: '项目总/日预算（元）',
                    customRender:(text, row) => {
                        return `${formatUnit(row.totalBudget)}/${formatUnit(row.dailyBudget)}` 
                    }
                },
                {
                    align: 'center',
                    title: '项目KPI类型',
                    customRender:(text, row) => {
                        return row.kpiType == 1 ? '线索量' : '-'
                    }
                },
                {
                    align: 'center',
                    title: '项目总/日KPI',
                    customRender:(text, row) => {
                        return `${row.totalKpi || '-'}/${row.dailyKpi || '-'}` 
                    }
                },
                {
                    align: 'center',
                    title: '操作',
                    scopedSlots: { customRender: 'action' }
                },
                {
                    align: 'center',
                    title: '创建时间',
                    dataIndex: 'ctime',
                    customRender:(text, row) => {
                        return text || '-'
                    }
                },
            ],
            dataList: [],
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0
            },
        }
    },
    created() {
        this.getPrincipalList()
        this.getDataList()
    },
    methods: {
        getPrincipalList() {
            this.$api.base_api.getPrincipalList().then(res => {
                if(res.code == 200) {
                    this.principalList = res.data
                } else {
                    this.principalList = []
                    console.error(`获取主体失败，${res}`)
                }
            })
        },
        handleSearch() {
            this.pagination.current = 1
            this.getDataList()
        },
        handleReset() {
            this.form = {}
            this.pagination.current = 1
            this.pagination.pageSize = 10
            this.getDataList()
        },
        handlePaginationChange(current, pageSize) {
            this.pagination.current = current
            this.pagination.pageSize = pageSize
            this.getDataList()
        },
        getDataList() {
            this.loading = true
            const _form = this.form
            let params = {
                ..._form,
                page: this.pagination.current,
                size: this.pagination.pageSize
            }
            this.$api.core.deliverManage.getProjectList(params).then((res) => {
                this.loading = false
                if(res.code == 200) {
                    this.dataList = res.data.list || []
                    this.pagination.total = res.data.total || 0
                } else {
                    this.dataList = []
                    this.pagination.total = 0
                    console.error(`获取列表失败${res}`)
                }
            })
        },
        jumpDetailPage(id) {
            this.$router.push({
                path: `/projectManage/detail`,
                query: {
                    id
                }
            })
        },
        deleteProject(id) {
            let that = this
            this.$confirm({
                title: '确定删除？',
                okText: '确定',
                cancelText: '取消',
                onOk () {
                    that.$api.core.deliverManage.deleteProject(id).then(res => {
                        if(res.code == 200) {
                            that.$message.success('删除成功')
                            that.getDataList()
                        } else {
                            that.$message.error('删除失败')
                        }
                    })
                }
            })
        },
        actionProject(type, id) {
            this.$api.core.deliverManage.actionProject({ type, id }).then(res => {
                if(res.code == 200) {
                    this.$message.success('操作成功')
                    this.getDataList()
                } else {
                    this.$message.error(`操作失败，${res.message}`)
                }
            })
        },
    }
}
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
}
.flex-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.mt-20 {
    margin-top: 20px;
}
</style>